import { AspectRatio, PhotoSize } from '../types/photo';
import { useAtom } from 'jotai';
import { ModelReturnType } from '@pages/api/model';
import { RouterOutputs } from '@lib/api';
import { Theme } from '@prisma/client';
import { atomWithStorage } from 'jotai/utils';

export const CART_STORAGE_KEY = 'shopping-cart-atom-v2';

export type CartTheme = Theme & {
  workflowName?: string;
  photoSize?: {
    aspectRatio: AspectRatio;
    size: PhotoSize;
  };
  quantity?: number;
  selectedModifiers?: {
    clothing?: string;
  };
  photoQuantity?: number;
};

export interface CartState {
  themes?: Pick<
    CartTheme,
    | 'id'
    | 'quantity'
    | 'photoSize'
    | 'workflowName'
    | 'modifiers'
    | 'image'
    | 'accentColor'
    | 'thumbnails'
    | 'name'
    | 'selectedModifiers'
    | 'photoQuantity'
  >[];
  model?: Pick<ModelReturnType, 'id' | 'name' | 'version' | 'trainingMeta' | 'trainingId'>;
}

const cartAtom = atomWithStorage<CartState>(CART_STORAGE_KEY, {});

export const useCartAtom = () => {
  const [cart, setCart] = useAtom(cartAtom);

  const addTheme = (theme: any) => {
    setCart(prevCart => ({ ...prevCart, themes: [...(prevCart.themes || []), theme] }));
  };

  const removeTheme = (id: string | number) => {
    setCart(prevCart => ({
      ...prevCart,
      themes: prevCart.themes?.filter(theme => theme.id !== id)
    }));
  };

  const setModel = (model: Omit<RouterOutputs['model']['get'], 'isComfy'>) => {
    setCart(prevCart => ({ ...prevCart, model }));
  };

  const updateThemeWorkflow = (themeId: string, workflowName: string) => {
    setCart(prevCart => {
      const themeIndex = prevCart.themes?.findIndex(theme => theme.id === themeId);
      if (themeIndex === -1) return prevCart;
      return {
        ...prevCart,
        themes: prevCart?.themes?.map((theme, index) => (index === themeIndex ? { ...theme, workflowName } : theme))
      };
    });
  };

  const updateThemePhotoQuantity = (themeId: string, photoQuantity: number) => {
    setCart(prevCart => {
      const themeIndex = prevCart.themes?.findIndex(theme => theme.id === themeId);
      if (themeIndex === -1) return prevCart;
      return {
        ...prevCart,
        themes: prevCart?.themes?.map((theme, index) => (index === themeIndex ? { ...theme, photoQuantity } : theme))
      };
    });
  };
  const updateThemePhotoSize = (themeId: string, aspectRatio: AspectRatio, size: PhotoSize) => {
    setCart(prevCart => {
      const themeIndex = prevCart.themes?.findIndex(theme => theme.id === themeId);
      if (themeIndex === -1) return prevCart;

      const newState = {
        ...prevCart,
        themes: prevCart?.themes?.map((theme, index) =>
          index === themeIndex ? { ...theme, photoSize: { aspectRatio, size } } : theme
        )
      };
      console.log(newState);
      return newState;
    });
  };

  const updateThemeQuantity = (themeId: string, quantity: number) => {
    setCart(prevCart => {
      const themeIndex = prevCart.themes?.findIndex(theme => theme.id === themeId);
      if (themeIndex === -1) return prevCart;
      return {
        ...prevCart,
        themes: prevCart?.themes?.map((theme, index) => (index === themeIndex ? { ...theme, quantity } : theme))
      };
    });
  };

  const updateThemeClothing = (themeId: string, clothing: string) => {
    setCart(prevCart => {
      const themeIndex = prevCart.themes?.findIndex(theme => theme.id === themeId);
      if (themeIndex === -1) return prevCart;
      return {
        ...prevCart,
        themes: prevCart?.themes?.map((theme, index) =>
          index === themeIndex ? { ...theme, selectedModifiers: { clothing } } : theme
        )
      };
    });
  };

  const updateThemeSelectedModifiers = (themeId: string, modifier: string, value: string) => {
    setCart(prevCart => {
      const themeIndex = prevCart.themes?.findIndex(theme => theme.id === themeId);
      if (themeIndex === -1) return prevCart;
      return {
        ...prevCart,
        themes: prevCart?.themes?.map((theme, index) =>
          index === themeIndex
            ? {
                ...theme,
                selectedModifiers: {
                  ...(theme.selectedModifiers || {}),
                  [modifier]: value
                }
              }
            : theme
        )
      };
    });
  };

  const totalItems = cart.themes?.reduce((acc, theme) => acc + (Number(theme.quantity) || 1), 0) || 0;
  const hasItems = totalItems > 0;

  const clearCart = () => {
    setCart({ themes: [], model: undefined });
  };

  return {
    cart,
    addTheme,
    removeTheme,
    setModel,
    updateThemeWorkflow,
    updateThemePhotoSize,
    updateThemeQuantity,
    updateThemeClothing,
    updateThemeSelectedModifiers,
    updateThemePhotoQuantity,
    totalItems,
    hasItems,
    clearCart
  };
};
